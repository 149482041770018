import Nav from "../nav/Nav";
import FadingExamplesCarousel from "../utils/FadingExamplesCarousel";

export default function Hero() {
  const examples = [
    {
      title: "fisherci.com",
      text: "Tip packaging uses 37% less plastic than leading competitor&apos;s packaging, saving on shipping weight and fossil fuels",
      score: "100.00",
      icon: "/hero/examples/link.svg",
    },
    {
      title: "Bill of Material - Impact weighting",
      text: "Calculated using LCA data for materials and a material composition prediction, based on product page data.",
      score: "99.49",
      icon: "/hero/examples/pie-chart.svg",
    },
    {
      title: "Transportation - Impact weighting",
      text: "Sent from manufacturing location in Shanghai to your site in Darmstadt.",
      score: "13.67",
      icon: "/hero/examples/truck.svg",
    },
    {
      title: "Production Energy - Impact weighting",
      text: "Based on an emissions intensity of 582 gCO2eq/kWh.",
      score: "28.01",
      icon: "/hero/examples/factory.svg",
    },
  ];

  return (
    <div className="relative bg-gradient-to-b from-[#374AEF] via-[#4F60F3] to-[#8D99FF]">
      <div className="absolute left-0 right-0 top-0">
        <Nav />
      </div>
      <div className="flex w-full flex-col items-center justify-between px-6 pb-10 py-20 sm:px-16 md:flex-row lg:px-[4vw] xl:px-[6vw]">
        <div className="flex w-full flex-col items-start justify-center gap-5 md:gap-7 md:w-1/2 lg:w-1/3">
          <div className="flex flex-col items-center justify-center gap-2">
            <h1 className="header mt-10 text-3xl text-white md:mt-0 md:text-4xl lg:text-5xl font-semibold">
              Your Eco-design Co&#8209;Pilot for Pharma
            </h1>
            <p className="text-lg text-white md:text-2xl">
              Sustainability by design decision support for R&D scientists,
              process engineers and product managers.
            </p>
          </div>
          <div className="flex w-full flex-row items-center justify-center md:justify-start">
            <a href="https://calendar.notion.so/meet/kamikrista/wk1qz4ga2">
              <button className="rounded bg-[#EE5A39] px-4 py-2 text-lg text-white hover:bg-[#D94A2D] md:px-8 md:py-4 md:text-xl font-semibold">
                Schedule a call
              </button>
            </a>
          </div>
        </div>
        <div className="mt-1 flex flex-col items-center justify-between gap-5 md:mt-0 md:flex-row md:items-start">
          <div className="hidden md:flex flex-col items-center justify-center gap-2">
            <img
              src={"/illustrations/elio_landing-page_desktop.png"}
              alt="hero"
              height={"auto"}
              className="aspect-[1072/920] max-h-[343px] md:max-h-[429px] lg:max-h-[515px] w-auto"
            />
            <div className="hidden md:flex xl:hidden ">
              <FadingExamplesCarousel examples={examples} />
            </div>
          </div>
          <div className="flex md:hidden">
            <img
              src={"/illustrations/elio_landing-page_mobile.png"}
              height={288}
              width={361}
              alt="hero"
              className="h-[288px] w-[361px]"
            />
          </div>

          <div className="md:hidden">
            <FadingExamplesCarousel examples={examples} />
          </div>
          <div className="hidden md:flex-col md:gap-4 xl:flex">
            {examples.map((example, idx) => (
              <div
                key={idx}
                className="flex w-[262px] flex-col gap-[10px] rounded bg-white px-[10px] py-4"
              >
                <div className="flex flex-row gap-1 text-[10px] text-[#7F90F7]">
                  <img src={example.icon} alt="link" width={14} height={14} />
                  {example.title}
                </div>
                <p className="text-[10px] font-light leading-3 text-[#4F4F4F]">
                  {example.text}
                </p>
                <div className="flex flex-row justify-between text-[10px]">
                  <div className="kite text-[#828282]">fx</div>
                  <div className="font-bold text-[#4D60FF]">
                    {example.score}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
