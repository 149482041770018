import Footer from "./components/footer/footer";
import Hero from "./components/hero/hero";
import Social from "./components/social/Social";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/ueb5zfy.css"
        ></link>
        <script src="/tailwind/main.js"></script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Hero />
      <Social />
      <Footer />
    </div>
  );
}

export default App;
