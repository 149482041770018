export default function Nav() {
  return (
    <div className="z-20 mx-auto flex h-[100px] w-full max-w-[2400px] flex-row items-center justify-between bg-transparent px-6 sm:px-16 lg:px-[4vw] xl:px-[6vw] ">
      <img
        src="/logo+text.png"
        alt="logo"
        width={100}
        height={100}
        style={{ paddingLeft: "1px" }}
      />
    </div>
  );
}
