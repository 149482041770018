import Marquee from "react-fast-marquee";

export default function Social() {
  const images = [
    { path: "/social-logos/automa-logo.png", alt: "Automa Health" },
    { path: "/social-logos/dechema-logo.png", alt: "Dechema" },
    { path: "/social-logos/eit-health-logo.png", alt: "EIT Health" },
    { path: "/social-logos/cphi-logo.png", alt: "CPhI" },
    { path: "/social-logos/logo-20.png", alt: "20" },
    { path: "/social-logos/pharmapack-logo.png", alt: "Pharmapack" },
  ];

  return (
    <div className="bg-[#EFF2FD] px-6 py-10 sm:px-16 lg:px-[4vw] xl:px-[6vw]">
      <div className="flex flex-row items-center justify-center gap-10">
        <div className="h-[0.75px] w-full bg-[#6E77C7]"></div>
        <div className="text-nowrap text-2xl leading-6 text-[#6E77C7]">
          As seen at/in
        </div>
        <div className="h-[0.75px] w-full bg-[#6E77C7]"></div>
      </div>

      <div className="xl:hidden">
        <Marquee className="pt-10" speed={30}>
          {images.map((image, idx) => (
            <div key={idx} className="px-10">
              <img src={image.path} alt={image.alt} width={150} height={250} />
            </div>
          ))}
        </Marquee>
      </div>

      <div className="hidden flex-col items-center justify-center gap-20 pt-10 xl:flex xl:flex-row">
        {images.map((image, idx) => (
          <img
            key={idx}
            src={image.path}
            alt={image.alt}
            width={150}
            height={250}
          />
        ))}
      </div>
    </div>
  );
}
