export default function Footer() {
  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-b from-[#6E7CF9] to-[#8D99FF] p-10">
      <iframe
        title="Substack Signup"
        src="https://elioearth.substack.com/embed"
        className="h-[250px] w-[300px] rounded"
      ></iframe>
      <img
        src={"/logo+text.svg"}
        alt="elio"
        width={150}
        height={100}
        className="mt-5"
      />
      <div className="mt-5 flex flex-col items-center justify-center gap-2">
        <a className="text-white" href="mailto:founders@elio.earth">
          hello@elio.earth
        </a>
        <div className="flex flex-row items-center justify-center gap-5">
          <a href="https://www.linkedin.com/company/elio-earth">
            <img
              id="socials"
              src={"/sharing-logos/LinkedIn.png"}
              alt="linkedin"
              width={20}
              height={20}
            />
          </a>
          <a href="https://twitter.com/ElioEarth">
            <img
              id="socials"
              src={"/sharing-logos/Twitter.png"}
              alt="twitter"
              width={20}
              height={20}
            />
          </a>
          <a href="https://www.instagram.com/elio.earth">
            <img
              id="socials"
              src={"/sharing-logos/Instagram.png"}
              alt="instagram"
              width={20}
              height={20}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
