"use client";

import React, { useState, useEffect } from "react";

const FadingExamplesCarousel = ({
  examples,
}: {
  examples: { title: string; text: string; score: string; icon: string }[];
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);

      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % examples.length);
        setIsVisible(true);
      }, 500); // Wait for fade out before changing
    }, 3000); // Total time for each item

    return () => clearInterval(intervalId);
  }, [examples.length]);

  const example = examples[currentIndex];

  if (!example) return null;

  return (
    <div className="xl:flex xl:flex-col xl:gap-4">
      <div
        className={`transition-opacity duration-1000 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex w-[262px] h-[110px] flex-col justify-between gap-[10px] rounded bg-white px-[10px] py-4">
          <div>
            <div className="flex flex-row gap-1 text-[10px] text-[#7F90F7]">
              <img src={example.icon} alt="link" width={14} height={14} />
              {example.title}
            </div>
            <p className="text-[10px] font-light leading-3 text-[#4F4F4F]">
              {example.text}
            </p>
          </div>
          <div className="flex flex-row justify-between text-[10px]">
            <div className="kite text-[#828282]">fx</div>
            <div className="font-bold text-[#4D60FF]">{example.score}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FadingExamplesCarousel;
